.App {
  text-align: center;
}

.buttonTour{
  background-color: linear-gradient(90deg, #005EB8 2.01%, #003A70 100%);
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.cardApp{
  padding: 20px 20px 20px 20px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  justify-content: center;
  width: 90vw;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button.ant-btn{
  border-radius: 100px;
}

button.ant-btn-primary {
  background: #003A70;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #003A70; /* Color de fondo al pasar el ratón */
  color: #fff; /* Color del texto al pasar el ratón */
}

.ant-btn-primary:disabled {
  background-color: #003A70;
  color: #fff;
  opacity: 50%;
}

.ant-notification-notice {
  border-radius: 10px;
  padding: 8px 16px 8px 24px !important;
}

.ant-notification-notice-message {
  font-weight: bold;
  margin-bottom: 0;
}

.ant-notification-notice-description {
  font-size: 12px;
}

.ant-notification-notice-icon {
  display: flex;
  align-items: center;
}

.ant-notification-notice-error {
  border: 3px solid #FF6666;
  border-left-width: 12px;
  background-color: #FFE0E0;
  align-items: center;
}

.ant-notification-notice-success {
  border: 3px solid #3EC161;
  border-left-width: 12px;
  background-color: #D8F3DF;
}

.ant-notification-notice-warning {
  border: 3px solid #FFDD66;
  border-left-width: 12px;
  background-color: #FFF8E0;
}

.ant-notification-notice-info {
  border: 3px solid #BCC7FF;
  border-left-width: 12px;
  background-color: #F2F4FF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

